<template>
  <v-container transition="scale-transition">
    <v-row class="text-center">
      <v-col>
        <span class="display-2 font-weight-bold">Math Game</span>
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="gameState === 'NOT_LOGIN'">
      <v-col>
        <v-card elevation="2" class="mx-auto" max-width="800">
          <v-card-title>Please enter your name</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="username" label="Name" v-on:keyup.enter="login()"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="teal accent-4" @click="login()">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="4">
        <v-card elevation="2">
          <v-card-title>Welcome, {{ username }}!</v-card-title>
          <v-card-text>
            <div class="text--primary">
              <span class="font-weight-bold"
                >Correct Answers: {{ correctAnswers }}</span
              ><br />
              <span class="font-weight-bold"
                >Wrong Answers: {{ wrongAnswers }}</span
              >
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="teal accent-4" @click="logout()"> Logout </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="8">
        <v-card elevation="2">
          <v-card-text>
            <div class="text--primary">
              What's {{ this.question.operand1 }} +
              {{ this.question.operand2 }} = ?
            </div>
            <v-text-field v-model="answer" label="Answer" type="number" v-on:keyup.enter="checkAnswer()"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="teal accent-4" @click="checkAnswer()" autofocus>
              Check Answer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      correctAnswers: 0,
      wrongAnswers: 0,
      gameState: "NOT_LOGIN",
      username: null,
      question: {
        operand1: 0,
        operand2: 0,
      },
      answer: null,
    };
  },
  methods: {
    login() {
      if (this.username) {
        this.gameState = "LOGIN";
      }
    },
    logout() {
      this.gameState = "NOT_LOGIN";
      this.username = null;
      this.correctAnswers = 0;
      this.wrongAnswers = 0;
      this.answer = null;
    },
    generateQuestion() {
      this.answer = null;
      this.question.operand1 = Math.floor(Math.random() * 10);
      this.question.operand2 = Math.floor(Math.random() * 10);
    },
    checkAnswer() {
      if (!this.answer) {
        return;
      }
      if (this.answer == this.question.operand1 + this.question.operand2) {
        this.correctAnswers++;
      } else {
        this.wrongAnswers++;
      }
      this.generateQuestion();
    },
  },
  mounted() {
    this.generateQuestion();
  },
};
</script>