<template>
  <v-container>
    <div transition="scale-transition">
      <dashboard-quote-of-the-day></dashboard-quote-of-the-day>
      <v-row class="text-center">
        <v-col :md="5">
          <v-row>
            <v-col class="pt-0">
              <dashboard-weather-forecast></dashboard-weather-forecast>
            </v-col>
            <v-col
              :class="{
                'pt-0': $vuetify.breakpoint.sm || $vuetify.breakpoint.xl,
              }"
            >
              <dashboard-crypto-exchange-rates></dashboard-crypto-exchange-rates>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <dashboard-launch-schedule></dashboard-launch-schedule>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="7" class="pt-0">
          <dashboard-reddit-feeds></dashboard-reddit-feeds>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import DashboardCryptoExchangeRates from "@/components/dashboard/CryptoExchangeRates";
import DashboardLaunchSchedule from "@/components/dashboard/LaunchSchedule";
import DashboardRedditFeeds from "@/components/dashboard/RedditFeeds";
import DashboardWeatherForecast from "@/components/dashboard/WeatherForecast";
import DashboardQuoteOfTheDay from "@/components/dashboard/QuoteOfTheDay";
export default {
  name: "Dashboard",

  data: () => ({}),
  components: {
    DashboardCryptoExchangeRates,
    DashboardRedditFeeds,
    DashboardWeatherForecast,
    DashboardLaunchSchedule,
    DashboardQuoteOfTheDay,
  },
};
</script>
