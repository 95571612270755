<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Crypto Exchange Rates</v-toolbar-title>
    </v-toolbar>
    <div v-if="!cryptoExchangeRates">
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
    </div>
    <v-list dense v-if="cryptoExchangeRates">
      <template v-for="(crypto,index) in selectedCrypto">
        <v-divider v-if="index !== 0" :key="index"></v-divider>
        <v-list-item :key="crypto">
          <v-list-item-content>{{crypto}}-USD:</v-list-item-content>
          <v-list-item-content align="right">
            <v-list-item-title>$ {{1.0 / cryptoExchangeRates[crypto]}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>Source: Coinbase ({{this.lastUpdated}})</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      cryptoExchangeRates: null,
      selectedCrypto: ["BTC", "BCH", "ETH", "XTZ"], //["BTC", "XRP", "ETH", "XTZ"],
      lastUpdated: null,
      feedTimer: null
    };
  },
  methods: {
    getExchangeRates: function() {
      axios
        .get(`https://api.coinbase.com/v2/exchange-rates`)
        .then(response => {
          this.cryptoExchangeRates = response.data.data.rates;
          this.lastUpdated = new Date().toLocaleString();

          // Clear the existing timeout
          clearTimeout(this.feedTimer);

          // delay call the exchange rate to the next minute
          this.feedTimer = setTimeout(() => {
            this.getExchangeRates();
          }, 30000);
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.getExchangeRates();
  }
};
</script>