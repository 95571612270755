<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Reddit Feeds</v-toolbar-title>
    </v-toolbar>
    <div v-if="!redditFeeds">
      <v-skeleton-loader
        tile
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
    </div>
    <v-list two-line v-if="redditFeeds">
      <template v-for="(feed, index) in redditFeeds">
        <v-divider v-if="index !== 0" :key="index"></v-divider>
        <v-list-item :key="feed.data.id" @click="openUrl(feed.data.permalink)">
          <v-list-item-avatar
            tile
            v-if="feed.data.thumbnail.startsWith('https')"
          >
            <v-img :src="feed.data.thumbnail"></v-img>
          </v-list-item-avatar>
          <v-list-item-content :key="feed.data.id" avatar align="left">
            <v-list-item-title
              >[{{ feed.data.subreddit_name_prefixed }}]
              {{ feed.data.title }}</v-list-item-title
            >
            <v-list-item-subtitle>{{
              feed.data.selftext
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      redditFeeds: null,
      itemLimit: null,
      feedTimer: null,
    };
  },
  components: {},
  methods: {
    openUrl(url) {
      window.open(`https://www.reddit.com${url}`, "_blank");
    },
    getRedditFeeds: function () {
      if (this.$vuetify.breakpoint.xl) {
        this.itemLimit = 10;
      } else {
        this.itemLimit = 14;
      }
      axios
        .get(`https://www.reddit.com/top/.json?limit=${this.itemLimit}`)
        .then((response) => {
          this.redditFeeds = response.data.data.children;

          // Clear the existing timeout
          clearTimeout(this.feedTimer);

          // delay call the exchange rate to the next minute
          this.feedTimer = setTimeout(() => {
            this.getRedditFeeds();
          }, 25000);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getRedditFeeds();
  },
};
</script>