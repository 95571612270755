<template>
  <v-container>
    <div transition="scale-transition">
      <v-row class="text-center">
        <v-col cols="12">
          <v-img src="frostwolf.png" class="my-3" contain :max-height="maxImageSize" />
        </v-col>
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">Welcome to Frostshoxx.com</h1>
          <p
            class="subheading font-weight-regular"
          >where I experiment and make prototypes with stuff</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col :sm="6" :md="6" :lg="3">
          <router-link to="/dashboard">
            <v-card class="mx-auto" raised ripple max-width="300">
              <v-img class="white--text align-end justify-right" src="dashboard.png">
                <v-card-title class="secondary justify-center">Dashboard</v-card-title>
              </v-img>
            </v-card>
          </router-link>
        </v-col>
        <v-col :sm="6" :md="6" :lg="3">
          <router-link to="/crypto">
            <v-card class="mx-auto" raised ripple max-width="300">
              <v-img class="white--text align-end justify-right" src="cryptoexchange.png">
                <v-card-title class="secondary justify-center">Crypto Exchange Rates</v-card-title>
              </v-img>
            </v-card>
          </router-link>
        </v-col>
        <v-col :sm="6" :md="6" :lg="3">
          <router-link to="/launch">
            <v-card class="mx-auto" raised ripple max-width="300">
              <v-img class="white--text align-end justify-center" src="launchschedule.png">
                <v-card-title class="secondary justify-center">Space Launch Schedule</v-card-title>
              </v-img>
            </v-card>
          </router-link>
        </v-col>
        <v-col :sm="6" :md="6" :lg="3">
          <router-link to="/reddit">
            <v-card class="mx-auto" raised ripple max-width="300">
              <v-img class="white--text align-end justify-left" src="redditfeeds.png">
                <v-card-title class="secondary justify-center">Reddit Feeds</v-card-title>
              </v-img>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({}),
  computed: {
    maxImageSize: function() {
      return 280;
    }
  }
};
</script>
