<template>
  <div class="space-traders">
    <SpaceTraders/>
  </div>
</template>

<script>
// @ is an alias to /src
import SpaceTraders from '@/components/SpaceTraders.vue'

export default {
  name: 'spaceTraderrs',
  components: {
    SpaceTraders
  }
}
</script>
