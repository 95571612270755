<template>
  <div class="launch">
    <Launch/>
  </div>
</template>

<script>
// @ is an alias to /src
import Launch from '@/components/LaunchSchedule.vue'

export default {
  name: 'launch',
  components: {
    Launch
  }
}
</script>
