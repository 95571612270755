<template>
  <div class="reddit">
    <Reddit/>
  </div>
</template>

<script>
// @ is an alias to /src
import Reddit from '@/components/RedditFeeds.vue'

export default {
  name: 'reddit',
  components: {
    Reddit
  }
}
</script>
