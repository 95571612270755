<template>
  <div class="math-game">
    <MathGame/>
  </div>
</template>

<script>
// @ is an alias to /src
import MathGame from '@/components/MathGame.vue'

export default {
  name: 'mathGame',
  components: {
    MathGame
  }
}
</script>
