<template>
  <v-container transition="scale-transition">
    <v-row class="text-center">
      <v-col>
        <h1 class="display-2 font-weight-bold">Reddit Feeds</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="6">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Top Reddit</v-toolbar-title>
          </v-toolbar>
          <div v-if="!redditFeeds">
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </div>
          <v-list two-line v-if="redditFeeds">
            <template v-for="(feed,index) in redditFeeds">
              <v-divider v-if="index !== 0 && index < itemLimit" :key="index"></v-divider>
              <v-list-item
                v-if="index < itemLimit"
                :key="feed.data.id"
                @click="openUrl(feed.data.permalink)"
              >
                <v-list-item-avatar tile v-if="feed.data.thumbnail.startsWith('https')">
                  <v-img :src="feed.data.thumbnail"></v-img>
                </v-list-item-avatar>
                <v-list-item-content :key="feed.data.id" avatar align="left">
                  <v-list-item-title>[{{feed.data.subreddit_name_prefixed}}] {{feed.data.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{feed.data.selftext}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col sm="12" md="6">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>The Sims</v-toolbar-title>
          </v-toolbar>
          <div v-if="!simsFeeds">
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
          </div>
          <v-list two-line v-if="simsFeeds">
            <template v-for="(feed,index) in simsFeeds">
              <v-divider v-if="index !== 0 && index < itemLimit" :key="index"></v-divider>
              <v-list-item
                v-if="index < itemLimit"
                :key="feed.data.id"
                @click="openUrl(feed.data.permalink)"
              >
                <v-list-item-avatar tile v-if="feed.data.thumbnail.startsWith('https')">
                  <v-img :src="feed.data.thumbnail"></v-img>
                </v-list-item-avatar>
                <v-list-item-content :key="feed.data.id" avatar align="left">
                  <v-list-item-title>{{feed.data.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{feed.data.selftext}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col sm="12" md="6">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Politics</v-toolbar-title>
          </v-toolbar>
          <div v-if="!politicsFeeds">
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
          </div>
          <v-list two-line v-if="politicsFeeds">
            <template v-for="(feed,index) in politicsFeeds">
              <v-divider v-if="index !== 0 && index < itemLimit" :key="index"></v-divider>
              <v-list-item
                v-if="index < itemLimit"
                :key="feed.data.id"
                @click="openUrl(feed.data.permalink)"
              >
                <v-list-item-avatar tile v-if="feed.data.thumbnail.startsWith('https')">
                  <v-img :src="feed.data.thumbnail"></v-img>
                </v-list-item-avatar>
                <v-list-item-content :key="feed.data.id" avatar align="left">
                  <v-list-item-title>{{feed.data.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{feed.data.selftext}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col sm="12" md="6">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Coronavirus</v-toolbar-title>
          </v-toolbar>
          <div v-if="!coronavirusFeeds">
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader tile type="list-item-avatar-two-line"></v-skeleton-loader>
          </div>
          <v-list two-line v-if="coronavirusFeeds">
            <template v-for="(feed,index) in coronavirusFeeds">
              <v-divider v-if="index !== 0 && index < itemLimit" :key="index"></v-divider>
              <v-list-item
                v-if="index < itemLimit"
                :key="feed.data.id"
                @click="openUrl(feed.data.permalink)"
              >
                <v-list-item-avatar tile v-if="feed.data.thumbnail.startsWith('https')">
                  <v-img :src="feed.data.thumbnail"></v-img>
                </v-list-item-avatar>
                <v-list-item-content :key="feed.data.id" avatar align="left">
                  <v-list-item-title>{{feed.data.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{feed.data.selftext}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      redditFeeds: null,
      simsFeeds: null,
      politicsFeeds: null,
      coronavirusFeeds: null,
      itemLimit: 4,
      feedTimer: null
    };
  },
  components: {},
  methods: {
    openUrl(url) {
      window.open(`https://www.reddit.com${url}`, "_blank");
    },
    getRedditFeeds: function() {
      const topReddit = `https://www.reddit.com/hot/.json?limit=${this.itemLimit}`;
      const topSims = `https://www.reddit.com/r/thesims/hot/.json?f=flair_name%3A::Build::&limit=${this.itemLimit}`;
      const topPolitics = `https://www.reddit.com/r/politics/hot/.json?limit=${this.itemLimit}`;
      const topCoronavirus = `https://www.reddit.com/r/coronavirus/hot/.json?limit=${this.itemLimit}`;

      axios
        .all([axios.get(topReddit), axios.get(topSims), axios.get(topPolitics), axios.get(topCoronavirus)])
        .then(
          axios.spread((...responses) => {
            this.redditFeeds = responses[0].data.data.children;
            this.simsFeeds = responses[1].data.data.children;
            this.politicsFeeds = responses[2].data.data.children;
            this.coronavirusFeeds = responses[3].data.data.children;

            // Clear the existing timeout
            clearTimeout(this.feedTimer);

            // delay call the exchange rate to the next minute
            this.feedTimer = setTimeout(() => {
              this.getRedditFeeds();
            }, 30000);
          })
        )
        .catch(errors => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.getRedditFeeds();
  }
};
</script>