<template>
  <v-row class="text-center">
    <v-col>
      <template v-for="(feed, index) in topQuoteOfTheDay">
        <v-divider v-if="index !== 0" :key="index"></v-divider>
        <v-list-item :key="feed.data.id" @click="openUrl(feed.data.permalink)">
          <v-list-item-content :key="feed.data.id" align="center">
            <v-list-item-title>
              {{ overWriteQuote(feed.data.title) }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      topQuoteOfTheDay: null,
    };
  },
  components: {},
  methods: {
    openUrl(url) {
      window.open(`https://www.reddit.com${url}`, "_blank");
    },
    overWriteQuote: function (quote) {
      return quote.replace("- My", "- Someone Else's");
    },
    getTopQuoteOfTheDay: function () {
      this.itemLimit = 1;
      axios
        .get(
          `https://www.reddit.com/r/quotes/top/.json?limit=${this.itemLimit}`
        )
        .then((response) => {
          this.topQuoteOfTheDay = response.data.data.children;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getTopQuoteOfTheDay();
  },
};
</script>