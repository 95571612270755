<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Weather Forecast</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="weatherForecast">{{weatherForecast.name}}</v-toolbar-title>
    </v-toolbar>
    <div v-if="!weatherForecast">
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
    </div>
    <v-list dense v-if="weatherForecast">
      <v-list-item>
        <v-list-item-content>Weather:</v-list-item-content>
        <v-spacer></v-spacer>
        <v-list-item-content align="right">
          <v-list-item-title>{{ weatherForecast.weather[0].main }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Humidity:</v-list-item-content>
        <v-spacer></v-spacer>
        <v-list-item-content align="right">
          <v-list-item-title>{{ weatherForecast.main.humidity }}%</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Avg Temp:</v-list-item-content>
        <v-spacer></v-spacer>
        <v-list-item-content align="right">
          <v-list-item-title>{{ weatherForecast.main.temp }}F</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Max Temp:</v-list-item-content>
        <v-spacer></v-spacer>
        <v-list-item-content align="right">
          <v-list-item-title>{{ weatherForecast.main.temp_max }}F</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Min Temp:</v-list-item-content>
        <v-spacer></v-spacer>
        <v-list-item-content align="right">
          <v-list-item-title>{{ weatherForecast.main.temp_min }}F</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      weatherForecast: null,
      geoIpData: null
    };
  },
  mounted() {
    axios
      .get(`https://ipinfo.io`)
      .then(response => {
        this.geoIpData = response.data;
      })
      .catch(() => {
        this.geoIpData = { city: "Portland", region: "Oregon" };
      })
      .finally(() => {
        axios
          .get(
            `https://api.openweathermap.org/data/2.5/weather?appid=bb76c06511d25b3b3fc41b7753448f91&units=imperial&q=${this.geoIpData.city},${this.geoIpData.region}`
          )
          .then(response => {
            this.weatherForecast = response.data;
          })
          .catch(e => {
            console.log(e);
          });
      });
  }
};
</script>