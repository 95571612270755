<template>
  <v-container transition="scale-transition">
    <v-row class="text-center">
      <v-col>
        <span class="display-2 font-weight-bold">Space Traders</span>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-card
          v-if="gameState === 'NOT_LOGIN'"
          elevation="2"
          class="mx-auto"
          max-width="800"
        >
          <v-toolbar>
            <v-toolbar-title>Server Status: {{ serverStatus }}</v-toolbar-title>
          </v-toolbar>
          <v-card-title>Please enter your token or username</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-model="token" label="Token"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="username"
                  label="Username"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="teal accent-4" @click="loginOrSignup()">
              Login
            </v-btn>
            <v-btn text color="teal accent-4" @click="openDoc()">
              Documentation
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else elevation="2" class="mx-auto" max-width="800">
          <v-toolbar>
            <v-toolbar-title>Server Status: {{ serverStatus }}</v-toolbar-title>
          </v-toolbar>
          <v-card-title>You're logged-in with token: {{ token }}</v-card-title>
          <v-card-text>
            <div class="text--primary" v-if="user">
              {{user}}
              <br />Username: {{user.username}}
              <br />Credits: {{user.credits}}
              <br />Ships: {{user.ships}}
              <br />Loans: {{user.loans}}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="teal accent-4" @click="logout()"> Logout </v-btn>
            <v-btn text color="teal accent-4" @click="openDoc()">
              Documentation
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      apiUrl: "https://api.spacetraders.io",
      serverStatus: "not available",
      gameState: "NOT_LOGIN",
      username: null,
      token: null,
      user: null,
    };
  },
  methods: {
    openDoc() {
      window.open(`https://spacetraders.io/`, "_blank");
    },
    loginOrSignup() {
      if (this.token) {
        this.login(this.token);
      }
      if (this.username) {
        this.signup(this.username);
      }
    },
    login(token) {
      if (token) {
        axios
          .get(`${this.apiUrl}/my/account`, { params: { token: token } })
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.user = response.data.user;
              this.gameState = "LOGIN";
            } else {
              this.gameState = "NOT_LOGIN";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    logout() {
      this.gameState = "NOT_LOGIN";
      this.token = null;
    },
    signup(username) {
      if (username) {
        axios
          .post(`${this.apiUrl}/users/${username}/claim`)
          .then((response) => {
            console.log(response);
            if (response.status === 201) {
              this.token = response.data.token;
              this.user = response.data.user;
              console.log(response.data.token);
              this.gameState = "LOGIN";
            } else {
              this.gameState = "NOT_LOGIN";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    axios
      .get(`${this.apiUrl}/game/status`)
      .then((response) => {
        this.serverStatus = response.data.status;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>