<template>
  <v-container transition="scale-transition">
    <v-row class="text-center">
      <v-col>
        <h1 class="display-2 font-weight-bold">Space Launch Schedule</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="12" lg="12" v-if="!this.launchSchedules">
        <v-skeleton-loader
          class="mx-auto"
          max-height="1185"
          max-width="524"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="12" md="12" lg="12" v-if="this.launchSchedules">
        <v-carousel cycle hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item
            v-for="(launchSchedule, i) in launchSchedules"
            :key="i"
          >
            <v-sheet height="100%">
              <v-card class="d-flex flex-nowrap">
                <v-img
                  cover
                  contain
                  v-if="launchSchedule.image"
                  :src="launchSchedule.image"
                ></v-img>
              </v-card>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      launchSchedules: null,
    };
  },
  mounted() {
    const now = new Date();
    this.launchSchedules = JSON.parse(localStorage.getItem("launchSchedules"));
    const launchSchedulesExpiry = JSON.parse(
      localStorage.getItem("launchSchedulesExpiry")
    );

    if (
      launchSchedulesExpiry == null ||
      now.getTime() > launchSchedulesExpiry
    ) {
      this.launchSchedules = null;
    }

    if (this.launchSchedules == null) {
      axios
        .get(
          `https://ll.thespacedevs.com/2.0.0/launch/upcoming/?format=json&fields=name,location,net,infoURLs,rocket&limit=8`
        )
        .then((response) => {
          this.launchSchedules = response.data.results;
          const launchSchedulesExpiry = now.getTime() + 60000;
          localStorage.setItem(
            "launchSchedules",
            JSON.stringify(this.launchSchedules)
          );
          localStorage.setItem("launchSchedulesExpiry", launchSchedulesExpiry);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
};
</script>