import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Crypto from '../views/Crypto.vue'
import Launch from '../views/Launch.vue'
import Reddit from '../views/Reddit.vue'
import SpaceTraders from '../views/SpaceTraders.vue'
import MathGame from '../views/MathGame.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/crypto',
    name: 'crypto',
    component: Crypto
  },
  {
    path: '/launch',
    name: 'launch',
    component: Launch
  },
  {
    path: '/reddit',
    name: 'reddit',
    component: Reddit
  },
  {
    path: '/space-traders',
    name: 'spaceTraders',
    component: SpaceTraders
  },
  {
    path: '/math-game',
    name: 'mathGame',
    component: MathGame
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
