<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Space Launch Schedule</v-toolbar-title>
    </v-toolbar>
    <div v-if="!launchSchedules">
      <v-skeleton-loader
        tile
        type="list-item-avatar-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        tile
        type="list-item-avatar-three-line"
      ></v-skeleton-loader>
    </div>
    <v-list two-line v-if="launchSchedules">
      <template v-for="(launchSchedule, index) in launchSchedules">
        <v-divider v-if="index !== 0" :key="index"></v-divider>
        <v-list-item
          :key="launchSchedule.id"
          avatar0
          :href="launchSchedule.rocket.wikiURL"
          target="_blank"
        >
          <v-list-item-avatar tile>
            <img
              v-if="launchSchedule.image"
              :src="launchSchedule.image.replace('1920', '320')"
            />
          </v-list-item-avatar>
          <v-list-item-content align="left">
            <v-list-item-title>{{ launchSchedule.name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              launchSchedule.net
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{
              launchSchedule.pad.location.name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      launchSchedules: null,
    };
  },
  mounted() {
    const now = new Date();
    this.launchSchedules= JSON.parse(localStorage.getItem("dashboardLaunchSchedules"));
    const dashboardLaunchSchedulesExpiry = JSON.parse(localStorage.getItem("dashboardLaunchSchedulesExpiry"));
    
    if (
      dashboardLaunchSchedulesExpiry == null ||
      now.getTime() > dashboardLaunchSchedulesExpiry
    ) {
      this.launchSchedules = null;
    }

    if (this.launchSchedules == null) {
      axios
        .get(
          `https://ll.thespacedevs.com/2.0.0/launch/upcoming/?format=json&fields=name,location,net,infoURLs,rocket&limit=5`
        )
        .then((response) => {
          this.launchSchedules = response.data.results;
          const launchSchedulesExpiry = now.getTime() + 60000;
          localStorage.setItem(
            "dashboardLaunchSchedules",
            JSON.stringify(this.launchSchedules)
          );
          localStorage.setItem(
            "dashboardLaunchSchedulesExpiry",
            launchSchedulesExpiry
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
};
</script>