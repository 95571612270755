<template>
  <v-container transition="scale-transition">
    <v-row class="text-center">
      <v-col>
        <h1 class="display-2 font-weight-bold">Crypto Exchange Rates</h1>
        <p class="subheading font-weight-regular" @click="openUrl">Source: Coinbase ({{this.lastUpdated}})</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="4" lg="4">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>USD</v-toolbar-title>
          </v-toolbar>
          <div v-if="!cryptoExchangeRatesForUsd">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </div>
          <v-list dense v-if="cryptoExchangeRatesForUsd">
            <template v-for="(crypto,index) in selectedCrypto">
              <v-divider v-if="index !== 0" :key="index"></v-divider>
              <v-list-item :key="crypto">
                <v-list-item-content>{{crypto}}-USD:</v-list-item-content>
                <v-list-item-content align="right">
                  <v-list-item-title>$ {{1.0 / cryptoExchangeRatesForUsd[crypto]}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>BTC</v-toolbar-title>
          </v-toolbar>
          <div v-if="!cryptoExchangeRatesForBtc">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </div>
          <v-list dense v-if="cryptoExchangeRatesForBtc">
            <template v-for="(crypto,index) in selectedCrypto">
              <v-divider v-if="index !== 0" :key="index"></v-divider>
              <v-list-item :key="crypto">
                <v-list-item-content>{{crypto}}-BTC:</v-list-item-content>
                <v-list-item-content align="right">
                  <v-list-item-title>{{1.0 / cryptoExchangeRatesForBtc[crypto]}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>ETH</v-toolbar-title>
          </v-toolbar>
          <div v-if="!cryptoExchangeRatesForEth">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </div>
          <v-list dense v-if="cryptoExchangeRatesForEth">
            <template v-for="(crypto,index) in selectedCrypto">
              <v-divider v-if="index !== 0" :key="index"></v-divider>
              <v-list-item :key="crypto">
                <v-list-item-content>{{crypto}}-ETH:</v-list-item-content>
                <v-list-item-content align="right">
                  <v-list-item-title>{{1.0 / cryptoExchangeRatesForEth[crypto]}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      cryptoExchangeRatesForBtc: null,
      cryptoExchangeRatesForUsd: null,
      cryptoExchangeRatesForEth: null,
      selectedCrypto: [
        "USDC",
        "EUR",
        "THB",
        "BTC",
        "XRP",
        "ETH",
        "XTZ",
        "BCH",
        "ZEC",
        "DASH",
        "BAT",
        "XLM",
        "LINK",
        "LTC",
        "ZRX",
        "DOGE",
        "SHIB"
      ],
      lastUpdated: null,
      feedTimer: null
    };
  },
  computed: {
    maxImageSize: function() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 160;
      } else {
        return 200;
      }
    }
  },
  methods: {
    openUrl: function() {
      window.open(`https://www.coinbase.com/join/rojana_v`, "_blank");
    },
    getExchangeRates: function() {
      const usd = "https://api.coinbase.com/v2/exchange-rates";
      const btc = "https://api.coinbase.com/v2/exchange-rates?currency=BTC";
      const eth = "https://api.coinbase.com/v2/exchange-rates?currency=ETH";
      const requestOne = axios.get(usd);
      const requestTwo = axios.get(btc);
      const requestThree = axios.get(eth);

      axios
        .all([requestOne, requestTwo, requestThree])
        .then(
          axios.spread((...responses) => {
            const responseOne = responses[0];
            const responseTwo = responses[1];
            const responseThree = responses[2];
            this.cryptoExchangeRatesForUsd = responseOne.data.data.rates;
            this.cryptoExchangeRatesForBtc = responseTwo.data.data.rates;
            this.cryptoExchangeRatesForEth = responseThree.data.data.rates;

            this.lastUpdated = new Date().toLocaleString();

            // Clear the existing timeout
            clearTimeout(this.feedTimer);

            // delay call the exchange rate to the next minute
            this.feedTimer = setTimeout(() => {
              this.getExchangeRates();
            }, 30000);
          })
        )
        .catch(errors => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.getExchangeRates();
  }
};
</script>